import { h } from 'preact';
import { useRef } from 'preact/hooks';
import { route, getCurrentUrl } from 'preact-router';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Container, Typography } from '@mui/material';
import Sign, { ISignHandle } from '../sign';
import useUserActions from '../../hooks/useUserActions';
import style from './style.css';

function getUrlParams() {
  if (typeof window === 'undefined') return {};
  const urlStr = window.location.href.split('?')[1];
  if (!urlStr) return {};
  const obj: Record<string, string> = {};
  const paramsArr = urlStr.split('&');
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    const arr = paramsArr[i].split('=');
    obj[arr[0]] = arr[1];
  }
  return obj;
}

const Header = () => {
  const url = getCurrentUrl().split('?')?.[0] ?? '/';
  const { isLogin, userInfo, logoutAction } = useUserActions(true);
  const signRef = useRef<ISignHandle>(null);

  const handleLogout = () => {
    logoutAction();
    return toast('退出登录成功!', { type: 'success' });
  };

  const handleGo = () => {
    const { pathname } = getUrlParams();
    let _pathname = 'quit';
    if (typeof window !== 'undefined') {
      _pathname = window.location.pathname.split('/')[1];
    }
    const backUrl = !pathname || pathname == 'quit' ? '/' : `/${pathname}`;
    route(url === '/vip' ? backUrl : `/vip?pathname=${_pathname}`);
  };

  return (
    <div>
      <header className={style.header}>
        <Container sx={{ height: '100%' }}>
          <Typography
            variant="h1"
            className={style.h1}
            onClick={() => route('/')}
            sx={{
              position: 'relative',
              display: { xs: isLogin ? 'none' : 'initial', sm: 'initial' },
            }}
          >
            好
            <img
              src="/assets/images/logo.png"
              style={{
                width: '30px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              alt="证明网 离职证明生成器 在线生成离职证明模板 好"
            />
            <span style={{ paddingLeft: '30px' }}>明网</span>
          </Typography>
          <nav className={style.nav}>
            {isLogin ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    color: '#ddd',
                    fontWeight: 'bold',
                    paddingRight: '6px',
                  }}
                >
                  {userInfo?.account}
                </span>
                <span
                  style={{
                    color: '#CD7F32',
                    fontWeight: 'bold',
                    paddingRight: '6px',
                  }}
                >
                  [{userInfo?.vipName}]
                </span>

                <Button
                  color="warning"
                  variant="contained"
                  size="small"
                  sx={{ marginRight: '10px' }}
                  onclick={handleGo}
                >
                  {url === '/vip' ? '返回首页' : '充值/续费VIP'}
                </Button>
                <Button
                  color="error"
                  size="small"
                  variant="contained"
                  onclick={handleLogout}
                >
                  退出
                </Button>
              </div>
            ) : (
              <ButtonGroup variant="contained" color="inherit">
                <Button variant="contained" size="small" onclick={handleGo}>
                  <span style={{ fontWeight: 'bold' }}>
                    {url === '/vip' ? '返回首页' : '开通VIP'}
                  </span>
                </Button>

                <Button
                  onclick={() => {
                    signRef.current?.handleOpen('login');
                  }}
                >
                  登录
                </Button>
                <Button
                  onclick={() => {
                    signRef.current?.handleOpen('register');
                  }}
                >
                  注册
                </Button>
              </ButtonGroup>
            )}
          </nav>
        </Container>
      </header>

      <Sign ref={signRef} />
    </div>
  );
};

export default Header;
